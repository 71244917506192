/* footer */
footer {
  align-self: flex-end;
  background-color: #333;
  color: rgb(255, 0, 0);
  width: 100%;
  font-family: 'Montserrat';
}
.container {
  margin: auto;
  max-width: 1200px;
}
.trademark {
  text-align: center;
}