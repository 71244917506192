/* app container */
.appContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* main */
main {
  flex-grow: 1;
  margin: auto;
  max-width: 1200px;
  font-family: 'Montserrat';
}
