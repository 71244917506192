/* header */
header {
  align-self: flex-start;
  background-color: #333;
  width: 100%;
  font-family: 'Montserrat';
}
.container {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1200px;
}
.logo {
  display: flex;
}
.nav {
  align-items: center;
  display: flex;
  flex: 0 0 60%;
}
.nav ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}
.nav li {
  margin: 0 1em;
}
.nav a {
  color: rgb(255, 0, 0);
  text-decoration: none;
}

@media (max-width: 768px) {
  .nav {
    display: none;
  }
  .logo {
    margin: auto;
  }
}